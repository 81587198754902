import React from "react"
import useTranslations from "../../utils/useTranslations"
import FeaturesList from "../../components/featuresList"
import ComparisonPanels from "../../components/comparisonPanels"
import Logo from "../../components/logo"
import LocalizedLink from "../../components/localizedLink"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"
import { ANDROID_LINK_FREE, getAndroidLink } from "../../utils/getAndroidLink";

import heroImgPng1 from "../../images/products/heroes/android/lightning-fast-android-hero.png"
import heroImgPng2 from "../../images/products/heroes/android/nospeed-sizelimit-android-hero.png"
import heroImgPng3 from "../../images/products/heroes/android/savedata-wifi-android-hero.png"
import heroImgPng4 from "../../images/products/heroes/android/dark-mode-android-hero.png"
import heroImgPng5 from "../../images/products/heroes/android/watch-downloading-android-hero.png"
import heroImgPng1_2x from "../../images/products/heroes/android/lightning-fast-android-hero@2x.png"
import heroImgPng2_2x from "../../images/products/heroes/android/nospeed-sizelimit-android-hero@2x.png"
import heroImgPng3_2x from "../../images/products/heroes/android/savedata-wifi-android-hero@2x.png"
import heroImgPng4_2x from "../../images/products/heroes/android/dark-mode-android-hero@2x.png"
import heroImgPng5_2x from "../../images/products/heroes/android/watch-downloading-android-hero@2x.png"
import heroImgWebp1 from "../../images/products/heroes/android/lightning-fast-android-hero.webp"
import heroImgWebp2 from "../../images/products/heroes/android/nospeed-sizelimit-android-hero.webp"
import heroImgWebp3 from "../../images/products/heroes/android/savedata-wifi-android-hero.webp"
import heroImgWebp4 from "../../images/products/heroes/android/dark-mode-android-hero.webp"
import heroImgWebp5 from "../../images/products/heroes/android/watch-downloading-android-hero.webp"
import heroImgWebp1_2x from "../../images/products/heroes/android/lightning-fast-android-hero@2x.webp"
import heroImgWebp2_2x from "../../images/products/heroes/android/nospeed-sizelimit-android-hero@2x.webp"
import heroImgWebp3_2x from "../../images/products/heroes/android/savedata-wifi-android-hero@2x.webp"
import heroImgWebp4_2x from "../../images/products/heroes/android/dark-mode-android-hero@2x.webp"
import heroImgWebp5_2x from "../../images/products/heroes/android/watch-downloading-android-hero@2x.webp"

const heroImgAlt = "Download µTorrent for Android phones"

class BittorrentAndroidFree extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      androidLinkFree: ANDROID_LINK_FREE,
    };
  }

  componentDidMount(){
    this.setState({
      androidLinkFree: getAndroidLink('free'),
    })
  }

  render(){
    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)

    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

    return (
      <>
        
        <div className="pb-md-5 pt-md-2">

          <div className="container text-dark py-2">

            <div className="d-flex text-center mt-4 my-sm-4 justify-content-center">
              
              <div className="order-md-12 mt-md-4 px-md-0 d-flex flex-column align-items-center">
                <Logo secondaryColor="black" tag="h1" productName="Android" className="mb-4"/>
                <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t('Get the #1 mobile torrent app on Google Play.')}</h1>
                <h2 className="text-size-16 text-spacing-1 font-weight-normal my-3">{t('Fast, easy torrent downloads on your phone or tablet')}</h2>
                <a href={this.state.androidLinkFree} id="product-android-free" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
                  <img className="google-play-button mb-4 mb-md-0 img-fluid" src={googlePlayButton} alt="µTorrent for Android Google Play Store badge"></img>
                </a>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4 android-hero"></img>*/}

                <picture>
                  {<source srcSet={`${heroImgWebp1} 1x, ${heroImgWebp1_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng1} 1x, ${heroImgPng1_2x} 2x`} src={heroImgPng1} alt="uTorrent Android - Lightning fast downloads" className="img-fluid d-block d-md-none mb-3 android-hero mr-0 mr-md-1"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp2} 1x, ${heroImgWebp2_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng2} 1x, ${heroImgPng2_2x} 2x`} src={heroImgPng2} alt="uTorrent Android - No speed or size limits"  className="img-fluid d-block d-md-none mb-3 android-hero mr-0 mr-md-1"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp3} 1x, ${heroImgWebp3_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng3} 1x, ${heroImgPng3_2x} 2x`} src={heroImgPng3} alt="uTorrent Android - Save data using wifi"  className="img-fluid d-block d-md-none mb-3 android-hero mr-0 mr-md-1"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp4} 1x, ${heroImgWebp4_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng4} 1x, ${heroImgPng4_2x} 2x`} src={heroImgPng4} alt="uTorrent Android - Dark mode support"  className="img-fluid d-block d-md-none mb-3 android-hero mr-0 mr-md-1"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp5} 1x, ${heroImgWebp5_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng5} 1x, ${heroImgPng5_2x} 2x`} src={heroImgPng5} alt="uTorrent Android - Watch while downloading"  className="img-fluid d-block d-md-none mb-3 android-hero"></img>
                </picture>

              </div>
              
            </div>
            
          </div>
          <div className="col-lg-12 mt-5 order-md-2 d-flex justify-content-center align-items-center">


                <picture>
                  {<source srcSet={`${heroImgWebp1} 1x, ${heroImgWebp1_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng1} 1x, ${heroImgPng1_2x} 2x`} src={heroImgPng1} alt="uTorrent Android - Lightning fast downloads" className="img-fluid d-none d-md-block hero-img android-hero mr-0 mr-md-2"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp2} 1x, ${heroImgWebp2_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng2} 1x, ${heroImgPng2_2x} 2x`} src={heroImgPng2} alt="uTorrent Android - No speed or size limits"  className="img-fluid d-none d-md-block hero-img android-hero mr-0 mr-md-2"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp3} 1x, ${heroImgWebp3_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng3} 1x, ${heroImgPng3_2x} 2x`} src={heroImgPng3} alt="uTorrent Android - Save data using wifi"  className="img-fluid d-none d-md-block hero-img android-hero mr-0 mr-md-2"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp4} 1x, ${heroImgWebp4_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng4} 1x, ${heroImgPng4_2x} 2x`} src={heroImgPng4} alt="uTorrent Android - Dark mode support"  className="img-fluid d-none d-md-block hero-img android-hero mr-0 mr-md-2"></img>
                </picture>

                <picture>
                  {<source srcSet={`${heroImgWebp5} 1x, ${heroImgWebp5_2x} 2x`} type="image/webp"></source>}
                  <img srcSet={`${heroImgPng5} 1x, ${heroImgPng5_2x} 2x`} src={heroImgPng5} alt="uTorrent Android - Watch while downloading"  className="img-fluid d-none d-md-block hero-img android-hero"></img>
                </picture>

              </div>
        </div>

        <div className="container-fluid overflow-hidden position-relative">
          <div className="container mt-0 mt-md-5 z-2">
            
            <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">
                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="font-weight-bold text-center feature-list-title">{t(`FeaturesHeader`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center feature-list-title-description">{t(`µTorrent Android Torrent App Features`)}</p>              
                </div>
              </div>

            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden panel-bg">
          <div className="container mb-5 z-2">
            <p className="text-center font-weight-bold feature-list-title mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare µTorrent Android Versions`)}
            </h2>
            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true}} >
            </ComparisonPanels>
          </div>
        </div>

        <TipsFaq type="tips" header={t(`Tips`)} subheader={t(`µTorrent Android Product Tip`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`What makes µTorrent Android the most popular mobile torrent app?`)}</p>
            <p className="product-faq-answer">{t(`µTorrent is known across the world for providing lightweight and fast torrent software for the Windows and Mac desktop, but did you know that it also offers the most popular mobile torrent app on Google Play with over 100 million downloads?`)}</p>
            <p className="product-faq-answer">{t(`The newest version of µTorrent Android comes packaged in a clean, intuitive design and makes it easy for you to download torrents directly from your phone. The Android torrent app relies on the BitTorrent P2P protocol to enable fast downloads of very large files. Just like our desktop torrent clients, µTorrent Android allows you to download more than one torrent file at a time. If you happen to be downloading music or video, the integrated media player provides excellent music listening and video playback.`)}</p>
            <p className="product-faq-answer">{t(`If you need to download a large file or have limited mobile data, a wifi-only mode allows you to lock your connection to local wifi networks instead of consuming your mobile data plan. Best of all, the µTorrent portable app allows you to stay connected, making files accessible when you’re on the go.`)}</p>
            <p className="product-faq-answer">{t(`If you need to download a file on your desktop computer at home, you can use µTorrent Android to remotely access µTorrent Classic on both Windows and Mac to add, remove, or manage your torrent downloads. `)}</p>
          </div>
        </TipsFaq>


      </>
    )

  }
}

export default BittorrentAndroidFree
